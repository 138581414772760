import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageRender from './PageRender'
import Header from './components/global/Header'
import Footer from './components/global/Footer'


import { Alert } from './components/alert/Alert'

import { refreshToken } from './redux/actions/authAction'
import { getCategories } from './redux/actions/categoryAction'
import { getHomeBlogs } from './redux/actions/blogAction'

import io from 'socket.io-client'

import SocketClient from './SocketClient'
import Search from './components/global/Search'
import AdminBlog from './pages/AdminBlog';
//import UserBlogs from './pages/UserBlogs';
import MyBlogs from './pages/MyBlogs';
import UserBlog from './pages/UserBlog';


const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCategories())
    dispatch(refreshToken())
    dispatch(getHomeBlogs())
  },[dispatch])

  useEffect(() => {
    const socket = io()
    dispatch({ type: 'SOCKET', payload: socket })
    return () => { socket.close() }
  },[dispatch])

  return (
    <div style={{marginLeft:'10px', marginRight:"10px"}}>
       <ToastContainer />
      <SocketClient />

      <Router>
        <Alert />
        <Header />
        <Search />

        <Switch>
          <Route exact path="/" component={PageRender} />
          <Route exact path="/:page" component={PageRender} />
          <Route exact path="/:page/:slug" component={PageRender} />
           <Route exact path='/adminblog' component={AdminBlog} /> 
           <Route exact path="/MyBlogs" component={MyBlogs} />
           <Route path="/UserBlog" component={UserBlog} />
        </Switch>

        <Footer/>
      </Router>
    </div>
  )
}

export default App

