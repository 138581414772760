import React from 'react';
import './aboutus.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>
        Welcome to Olean Project
      </p>

      <p>
        <b>Olean project is an e-learning</b>, research platform dedicated to providing our customers with the <b>best academic research materials which are meant to serve as a guide</b>. In the last six months, our bloggers have created over 15,000 new project materials for all departments.
      </p>
      <p>
        Topics ranging from various departments, Computer Science, Education, Accounting, Economics, English, Mass Communication, and Political Science, etc.
      </p>
      <p>
        We have you covered regardless of your department.
        Visit our website today
      </p>
      <p>
        www.oleanproject.com
      </p>

      <p>
        It's Quite Simple
      </p>

      <p>
        <b>Simply browse through our project department,</b>, view our free chapter 1, table of content or abstract
      </p>
      <p>
        Place an order to request for complete research material you are interested in
      </p>
      <p>
        Make payment either debit card or online transfer
      </p>
      <p>
        After payment SMS or chat us on WhatsApp 08117830247 with your name, topic paid for, and email address
      </p>
      <p>
        Once payment is confirmed, project blog material(s) will be sent within 30 minutes
      </p>
      <p>
       for more enquiries and questions please feel free to <a href="mailto:oleanproject@gmail.com">CONTACT US</a> 
      </p>
    </div>
  );
};

export default About;
