import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './payingUser.css'; // Import the CSS file

const PayingUser = () => {
  const location = useLocation();
  const { payers }: any = location.state;
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 20;

  // Logic for pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = payers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(payers.length / usersPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="paying-user-container">
      <h1 className="paying-user-heading">Paying Users</h1>
      {currentUsers && currentUsers.length > 0 ? (
        currentUsers.map((payer: any) => (
          <div className="paying-user-card" key={payer._id}>
            <p className="paying-user-info">Name: {payer.name}</p>
            <p className="paying-user-info">Email: {payer.email}</p>
            <p className="paying-user-info">Number: {payer.phone}</p>
          </div>
        ))
      ) : (
        <p>No paying users found.</p>
      )}

      {payers.length > usersPerPage && (
        <div className="pagination">
          {Array.from(Array(totalPages), (val, index) => index + 1).map(
            (pageNumber) => (
              <button
                key={pageNumber}
                className={`pagination-button ${
                  pageNumber === currentPage ? 'active' : ''
                }`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default PayingUser;
