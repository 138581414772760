import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { IBlog, IParams, RootStore } from '../../utils/TypeScript'

import { deleteBlog } from '../../redux/actions/blogAction'


interface IProps {
  blog: IBlog

}

const CardHoriz: React.FC<IProps> = ({blog}) => {
  const { slug } = useParams<IParams>()
  const { auth } = useSelector((state: RootStore) => state)
  const dispatch = useDispatch()

  const handleDelete = () => {
    if(!auth.user || !auth.access_token) return;

    if(slug !== auth.user._id) return dispatch({
      type: 'ALERT',
      payload: { errors: 'Invalid Authentication.' }
    })

    if(window.confirm("Do you want to delete this post?")){
      dispatch(deleteBlog(blog, auth.access_token))
    }
  }
  //console.log(blog)
  //console.log(blog.slug)

  return (
    <div className="card mb-3" style={{minWidth: "260px"}}>
      <div className="row g-0 p-2">
        {/* <div className="col-md-4" style={{
          minHeight: '150px', maxHeight: '170px', overflow: 'hidden'
        }}>
        
        </div> */}
        
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title">
              <Link to={`/project/${blog?.slug}`}
              className="text-capitalize text-decoration-none">
                {blog.title}
              </Link>
            </h5>
            <p className="card-text">{blog.methodology}</p>
            <p className='card-text'>Chapter {blog.chapter}</p>
            <p className='card-text'>Price ₦{blog.price}</p>
         

            {
              blog.title &&
              <div className="card-text d-flex justify-content-between
                align-items-center"
              >
                
                  <div style={{cursor: 'pointer'}}>
                    <Link to={`/update_blog/${blog._id}`}>
                      <i className="fas fa-edit" title="edit" />
                    </Link>

                    <i className="fas fa-trash text-danger mx-3" 
                    title="edit" onClick={handleDelete} />
                  </div>
                
                {/* <small className="text-muted">
                  {new Date(blog.createdAt).toLocaleString()}
                </small> */}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardHoriz
