import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getAPI } from "../utils/FetchData";
import { IBlog, RootStore } from "../utils/TypeScript";

import "./department.css";


const Departments = () => {
  const [, setBlogs] = useState<IBlog[]>([]);
  const [, setLoading] = useState<boolean>(false);
  const [, setCategoryBlogs] = useState<string[]>([]);
  const [, setBlog] = useState<IBlog[]>([]);
 
  const { categories } = useSelector((state: RootStore) => state);

  const { category } = useParams<{ category: string }>();
  const history = useHistory();

  //console.log(categoryBlogs);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const res = await getAPI(`research/${category}`);
        setBlogs(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchBlogs();
  }, [category]);

  useEffect(() => {
    const getBlog = async () => {
      setLoading(true);
      try {
        const res = await getAPI(`/home/researchs`);
        //console.log(res)
        setBlog(res.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    getBlog();
  }, []);

 

  const handleCategoryClick = async (categoryId: any, category:any) => {
    setLoading(true);
    try {
      const res = await getAPI(`researchs/category/${categoryId}`);
      
      const blogs = res.data.blogs.map((blog: IBlog) => ({
        id: blog._id,
        title: blog.title,
        user: blog.user,
        pages: blog.pages,
        slug:blog.slug,
        methodology: blog.methodology,
        createdAt: blog.createdAt,
        price: blog.price,
        chapter: blog.chapter,
      }));
      //console.log(blogs)
      setCategoryBlogs(blogs);
      setLoading(false);
      history.push({
        pathname: '/DepartmentArticles',
        state: { blogs: blogs, category: category },
         // Pass the blogs prop as part of the state object
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const sortedCategories = [...categories].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const toSentenceCase = (str: any) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

 

  return (
    <div className="departments-container">
     <h1>Departments</h1>
      <ul >
        {sortedCategories.map((category) => (
          <li key={category._id}>
            <p
              onClick={() => handleCategoryClick(category._id, category.name)}
              className="list"
            >
              {toSentenceCase(category.name)}
            </p>
          </li>
        ))}
      </ul>
     
  </div>
);
};

export default Departments;
