import React from "react";
import './aboutpayment.css'
const AboutPayment = () => {
  return (
    <div>
      <h2>About Payment</h2>
      <p>
        This page provides information about the payment process for purchasing
        a product. Here's an overview of the process:
      </p>

      <h3>Payment Options</h3>
      <p>
        We offer two payment options: card payment and bank transfer. You can
        choose whichever option suits you best.
      </p>

      <h4>Pay with Card</h4>
      <p>
        If you choose to pay with a card, you will be redirected to a payment
        gateway where you can enter your card details. Once the payment is
        successful, the PDF document will be sent to your email.
      </p>

      <h4>Pay with Bank Transfer</h4>
      <p>
        If you choose to pay via bank transfer, you will need to transfer the
        total amount to our bank account. You can find our bank details below.
        Once the payment is confirmed, send the receipt of your payment to our
        email or chat us on WhatsApp, and we will send the PDF document to your
        email.
        <span>All payment should be made to</span><br /><span>First Bank</span><br /><span>Account Name: Olean ltd</span><br /><span>Account Number: 2033124513</span>
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or complaints about the payment process, you
        can reach out to our admin team via email or phone.
      </p>

      <p>
        Note that the payment gateway used for card payment is Flutterwave. We
        do not store your card details, and all transactions are securely
        processed via the payment gateway.
      </p>

      <p>
        We hope this information helps. If you have any further questions, feel
        free to contact us.
      </p>
    </div>
  );
};

export default AboutPayment;
