import React from 'react'
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux'

import { IParams, RootStore } from '../../utils/TypeScript'

import UserInfo from '../../components/profile/UserInfo'
import OtherInfo from '../../components/profile/OtherInfo'
//import Userls from '../../components/profile/UserBlogs'

const Profile = () => {
  const { slug }: IParams = useParams()
  const { auth } = useSelector((state: RootStore) => state)
 //console.log(slug)
  return (
    <div className="row my-3 profile-page" >
      <div className="col-md-5 mb-3">
        {
          auth.user?._id === slug
          ? <UserInfo />
          : <OtherInfo id={slug} />
        }
          <div >
      <Link to={{ pathname: '/UserBlog', state: { slug } }} className="btn btn-primary mb-3">
    Fetch All Blogs
  </Link>
      </div>
        
      </div>
    
    </div>
  )
}

export default Profile
