import React from 'react'
//import UserBlogs from '../components/profile/UserBlogs'

const MyBlogs = () => {
  return (
    <div>
    
    </div>
  )
}

export default MyBlogs