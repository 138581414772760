import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory,useLocation } from "react-router-dom";
import { getBlogsByUserId } from "../redux/actions/blogAction";
import { RootStore, IBlog } from "../utils/TypeScript";
import Loading from "../components/global/Loading";
import CardHoriz from "../components/cards/CardHoriz";
import Pagination from "../components/global/Pagination";

const UserBlog = ({ id }: { id: string }) => {
  const { blogsUser }:any = useSelector((state: RootStore) => state);
  const dispatch = useDispatch();
  const location:any = useLocation();
  
  const user_id = location.state.slug
  //console.log(blogsUser)
//console.log(user_id);
  

  const [blogs, setBlogs] = useState<IBlog[]>();
  const [total, setTotal] = useState(0);

  const history = useHistory();
  const { search } = history.location;

  useEffect(() => {
    if (!user_id) return;

    if (blogsUser.every((item:any) => item.id !== user_id)) {
      dispatch(getBlogsByUserId(user_id, search));
    } else {
      const data = blogsUser.find((item:any) => item.id === user_id);
      if (!data) return;

      setBlogs(data.blogs);
      setTotal(data.total);
      if (data.search) history.push(data.search);
    }
  }, [user_id, blogsUser, dispatch, search, history]);

  const handlePagination = (num: number) => {
    const search = `?page=${num}`;
    dispatch(getBlogsByUserId(user_id, search));
  };

  if (!blogs) return <Loading />;

  if (blogs.length === 0 && total < 1) {
    return <h3 className="text-center">No Blogs</h3>;
  }

  return (
    <div>
      <div>
        {blogs.map((blog) => (
          <CardHoriz key={blog._id} blog={blog} />
        ))}
      </div>

      <div>
        <Pagination total={total} callback={handlePagination} />
      </div>
    </div>
  );
};

export default UserBlog;
