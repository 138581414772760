import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {  useHistory } from "react-router-dom";

import { RootStore, InputChange, IUserProfile, FormSubmit } from '../../utils/TypeScript';
//import Loading from '../global/Loading';

import NotFound from '../global/NotFound'
//import payingUser from '../../pages/payingUser'

import { updateUser, resetPassword } from '../../redux/actions/userAction'
import { getAPI } from '../../utils/FetchData';
//import { getBlogsByUserId } from '../../redux/actions/blogAction';

//import Loading from '../alert/Loading'

const UserInfo = () => {
  //const { blogsUser } = useSelector((state: RootStore) => state);
  //const user_id = useParams<IParams>().slug
  const history = useHistory()
  //const location:any = useLocation()
  const initState = {
      name: '', account: '', avatar: '', number: '', password: '', cf_password: ''
  }
  const { auth } = useSelector((state: RootStore) => state)
  const dispatch = useDispatch();
  


  const [user, setUser] = useState<IUserProfile>(initState)
  const [typePass, setTypePass] = useState(false);
  const [typecfPass, setTypeCfPass] = useState(false);
  const [, setPayers] = useState([])
  const [, setLoading] = useState(false);
  // const [blogs, setBlogs] = useState<IBlog[]>();
  // const [adminBlog, setAdminBlog]= useState<IBlog[]>([]);
  // const [total, setTotal] = useState(0);
  // const { search } = history.location
  
  
  
  const handleChangeInput = (e: InputChange) => {
      const {name, value} = e.target
      setUser({...user, [name]: value})
  }

  const handleChangeFile = (e: InputChange) => {
      const target = e.target as HTMLInputElement
      const files = target.files

      if(files){
          const file = files[0]
          setUser({...user, avatar: file})
      }
    
  }

  const handleSubmit = (e: FormSubmit) => {
      e.preventDefault()
      if(avatar || name )
      dispatch(updateUser((avatar as File), name, auth) as unknown as any)

      if(password && auth.access_token)
      dispatch(resetPassword(password, cf_password, auth.access_token) as unknown as any)

  }
 
    
  const handleFetch = () => {
    setLoading(true);
  
    // Fetch the data
    const fetchPayers = async () => {
      try {
        const res: any = await getAPI('payment-users');
        setPayers(res.data.payers);
        setLoading(false);
        
        // Push the results to the payingUser page
        history.push('/payingUser', { payers: res.data.payers });
        
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
  
    if (auth.user) {
      fetchPayers();
    }
  };


 

 
 
  if(!auth.user) return <NotFound />

  const {name, avatar, password, cf_password} = user as unknown as any


  return (
    <><form className='profile_info' onSubmit={handleSubmit}>
          <div className='info_avatar'>
              <img src={avatar ? URL.createObjectURL(avatar) : auth.user.avatar}
                  alt='avatar' />

              <span>
                  <i className='fas fa-camera' />
                  <p>Change</p>
                  <input type='file' accept='image/*'
                      name='file'
                      id='file_up'
                      onChange={handleChangeFile} />
              </span>

          </div>

          <div className='form-group my-3'>
              <label htmlFor='name'>Name</label>
              <input type='text' className='form-control' id='name'
                  name='name' defaultValue={auth.user.name}
                  onChange={handleChangeInput} />
          </div>

          <div className='form-group my-3'>
              <label htmlFor='account'>Account</label>
              <input type='text' className='form-control' id='account'
                  name='account' defaultValue={auth.user.account}
                  onChange={handleChangeInput} disabled={true} />
          </div>

          <div className='form-group my-3'>
              <label htmlFor='number'>number</label>
              <input type='text' className='form-control' id='number'
                  name='number' defaultValue={auth.user.number}
                  onChange={handleChangeInput} disabled={true} />
          </div>

          {auth.user.type !== 'register' &&
              <small className="text-danger">
                  * Quick login account with {auth.user.type} can't use this function *
              </small>}

          <div className='form-group my-3'>
              <label htmlFor='password'>Password</label>
              <div className='pass'>
                  <input type={typePass ? 'text' : 'password'}
                      className='form-control' id='password'
                      name='password' value={password}
                      onChange={handleChangeInput} />

                  <small onClick={() => setTypePass(!typePass)}>
                      {typePass ? 'Hide' : 'Show'}
                  </small>
              </div>
          </div>

          <div className='form-group my-3'>
              <label htmlFor='cf_password'>Confirm Password</label>
              <div className='pass'>
                  <input type={typecfPass ? 'text' : 'password'}
                      className='form-control' id='cf_password'
                      name='cf_password' value={cf_password}
                      onChange={handleChangeInput} />

                  <small onClick={() => setTypeCfPass(!typecfPass)}>
                      {typePass ? 'Hide' : 'Show'}
                  </small>
              </div>
          </div>

          <button className='btn btn-dark w-100 mt-3' type='submit'>
              Update
          </button>

      </form>
      {
  auth.user?.role === 'admin' && (
    <div className="fetch-data">
    <button type="button" className='btn' onClick={handleFetch}>
      Fetch Paying Users
    </button>
    <div style={{margin:'10px'}}></div>
   
    
  </div>
  
  
    )
}
    
          </>
  )
}

export default UserInfo
