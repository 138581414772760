import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
//import { IBlog } from '../utils/TypeScript';
import './departArt.css';

const DepartmentArticles = () => {
  const history = useHistory();
  const location = useLocation();
  const { blogs, category }: any = location.state || {};
  //console.log(blogs)
  const handleDisplay = (slug:string) => {
    history.push(`/project/${slug}`)
  }
  const toSentenceCase = (str: any) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="container">
      <p className="title">Projects in {category}</p>
      <ul>
        {blogs &&
          blogs.map((blog:any) => (
           
            <li key={blog._id} className="listItem" onClick={() => handleDisplay(blog.slug)}>
              
            <h3>{toSentenceCase(blog.title)}</h3>
              <p><span className='span'>Pages:</span> {blog.pages}</p>
              <p><span className='span'>Methodology:</span> {blog.methodology}</p>
              {/* <p><span className='span'>Created At:</span> {blog.createdAt}</p> */}
              <p><span className='span'>Price:</span> ₦{blog.price}</p>
              <p><span className='span'>Chapter:</span> {blog.chapter}</p>
              
            </li>
            
          ))}
          
      </ul>
      
    </div>
  );
};

export default DepartmentArticles;
