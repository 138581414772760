import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contact.css';
//import { ALERT } from '../redux/types/alertType';

const Contact = () => {
  const form:any = useRef();
  

  const sendEmail = (e:any) => {
    e.preventDefault();

    emailjs.sendForm('service_gc0j85a', 'template_zt5t7rg', form.current, 'p-tbLsuhs8gF_oSdT')
      .then((result) => {
          //console.log(result.text);
         
          toast.success('Message sent successfully!');
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <><h1>Contact Us</h1><div className="app__footer-cards">
      <div className="app__footer-card">
        <img src="https://nicklaus-portfolio.netlify.app/static/media/email.37b9e890eea501421fbf.png" alt="email" className='imgg' />
        <a href="mailto:example@example.com" className="p-text">oleanproject@gmail.com</a>
      </div>
      <div className="app__footer-card">
        <img src="https://nicklaus-portfolio.netlify.app/static/media/mobile.145d9ce0157a56f8fcd8.png" alt="phone" className='imgg' />
        <p> or </p>
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png" alt="whatsapp" className='imggw' />
        <a href="tel:+2348117830247" className="p-text">+2348117830247</a>
      </div>
    </div><div className='container-form'>
        <form ref={form} onSubmit={sendEmail} className='contactform'>
          <label className='contactform-label'>Name</label>
          <input type="text" className='contactform-input' name="user_name" />
          <label className='form-label'>Email</label>
          <input type="email" className='contactform-input' name="user_email" />
          <label className='contactform-label'>Message</label>
          <textarea name="message" className='contactform-textarea' />
          <input type="submit" value="Send" className='contactform-submit' />
        </form>
      </div></>
  );
};

export default Contact;
