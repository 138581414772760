import React from "react";
import { Link } from "react-router-dom";
//import slugify from "slugify";

import { IBlog } from "../../utils/TypeScript";

interface IProps {
  blog: IBlog;
}

const CardVert: React.FC<IProps> = ({ blog }) => {
  //const title = slugify(blog?.title || "", { lower: true });
  //console.log(title)
  return (
    <div className="card">
     

      <div className="card-body">
        <h5 className="card-title">
          <Link
            to={`/project/${blog.slug}`}
            style={{
              textDecoration: "none",
              textTransform: "capitalize",
            }}
          >
            {blog.title.slice(0, 50) + "..."}
          </Link>
        </h5>
        <p className="card-text">
          <span style={{ fontWeight: "bold", color: "purple" }}>
            {" "}
            Methodology:
          </span>{" "}
          {blog.methodology.slice(0, 100) + "..."}
        </p>
        <p
          className="card-text"
          style={{ fontWeight: "bold", color: "purple" }}
        >
          Chapters: {blog.chapter}
        </p>
        <p
          className="card-text"
          style={{ fontWeight: "bold", color: "purple" }}
        >
          Total Pages: {blog.pages}
        </p>
        <p
          className="card-text"
          style={{ fontWeight: "bold", color: "purple" }}
        >
          Price ₦{blog.price}
        </p>

        <p className="card-text d-flex justify-content-between">
          <small className="text-muted text-capitalize">
            {typeof blog.user !== "string" && (
              <Link
                to={`#`}
                style={{
                  textDecoration: "none",
                  textTransform: "capitalize",
                }}
              >
                By: {blog.user.name}
              </Link>
            )}
          </small>

          {/* <small className="text-muted">
            { new Date(blog.createdAt).toLocaleString() }
          </small> */}
        </p>
      </div>
    </div>
  );
};

export default CardVert;
