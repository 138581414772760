import React from "react";

interface Props {
  categoryBlogs: any[];
  handleDisplay: (id: string) => void;
}

const Card: React.FC<Props> = ({ categoryBlogs, handleDisplay }) => {
  return (
    <>
      {categoryBlogs.length > 0 ? (
        <>
          <h2>Articles in Selected Department</h2>
          <ul className="blog-titles">
            {categoryBlogs.map((blog: any, index) => (
              <li
                key={index}
                // onClick={() => handleBlog(blog._id)}
                className="blogli"
              >
                <div
                  className="blog-item"
                  onClick={() => handleDisplay(blog.id)}
                >
                  <div className="blog-title">
                    <span>Title: </span>
                    {blog.title}
                  </div>
                  <div className="blog-metadata">
                    {/* <span className="blog-author">{blog.user.name}</span> */}
                    <span className="blog-pages">{blog.pages} Pages</span>
                    <span className="blog-date">
                      <span>Methodology:</span> {blog.methodology}
                    </span>
                    <span className="blog-chapter">
                      <span>Chapters:</span>
                      {blog.chapter}
                    </span>
                    <span className="blog-price">
                      <span>Price:</span>₦{blog.price}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p>No articles found in this Department</p>
      )}
    </>
  );
};

export default Card;
