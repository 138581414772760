import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { postAPI } from '../../utils/FetchData';
//import { countryData } from '../../utils/countryData';
import './modal.css'
import { IBlog } from '../../utils/TypeScript';

interface IProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    blog: IBlog;
  }

export const Modal: React.FC<IProps> = ({ openModal, setOpenModal, blog }) => {
    const history = useHistory();
    
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  


const handleSubmit = async (event: any) => {
    event.preventDefault();
    //console.log('Form submitted:', { name, age, sex, country, email, phone });
    const data = { name, email, phone };
    const url = 'payment-details';
    try {
      const response = await postAPI(url, data);
      //console.log('Server response:', response);
  
      if (response.status === 200) {
        history.push({
          pathname: '/Payment',
          state: { email, name, phone, blog }
        });
        toast.success('Form submitted successfully!');
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  
  
  
  


  return (
    <>
  
   
        <div className="modal">
          <div className="modal-content">
            <h2>To start your download enter your information below</h2>
            
            <form onSubmit={handleSubmit} className='modal-form'>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                className='modal-input'
              />
       
            
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                className='modal-input'
              />
              <label htmlFor="phone">Phone Number:</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                className='modal-input'
              />
              <button type="submit">Submit</button>
            </form>
            <div className="titleCloseBtn">
            <button
                className="modal-footer-button modal-button-cancel"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </button>
            
            </div>
          </div>
        </div>
    
    </>
  );
};


