import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'


import { IParams, IBlog, RootStore } from '../../utils/TypeScript'
import { getAPI } from '../../utils/FetchData'

import Loading from '../../components/global/Loading'
import { showErrMsg } from '../../components/alert/Alert'
import DisplayBlog from '../../components/blog/DisplayBlog'

const DetailBlog = () => {
  const slug = useParams<IParams>().slug
  //console.log(useParams)
  //console.log(slug)
  const { socket } = useSelector((state: RootStore) => state)

  const [blog, setBlog] = useState<IBlog>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  

  useEffect(() => {
    if(!slug) return;

    setLoading(true)

    getAPI(`project/${slug}`)
    .then(res => {
      setBlog(res.data)
      setLoading(false)
        
    })
  
    .catch(err => {
      setError(err.response.data.msg)
      setLoading(false)
    })

    return () => setBlog(undefined)
  },[slug])

  // Join Room
  useEffect(() => {
    if(!slug || !socket) return;
    socket.emit('joinRoom', slug)

    return () => {
      socket.emit('outRoom', slug)
    }
  },[slug, socket])
  

  if(loading) return <Loading />;
  return (
    <div className="my-4">
      { error && showErrMsg(error) }
      
      { blog && <DisplayBlog blog={blog} /> }

    </div>
  )
}

export default DetailBlog
