import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { InputChange, FormSubmit } from '../../utils/TypeScript'
import { login } from '../../redux/actions/authAction'

const LoginPass = () => {
  const initialState = { account: '', password: '' }
  const [userLogin, setUserLogin] = useState(initialState)
  const { account, password } = userLogin

  const [typePass, setTypePass] = useState(false)

  const dispatch = useDispatch()

  const handleChangeInput = (e: InputChange) => {
    const { value, name } = e.target
    setUserLogin({ ...userLogin, [name]: value })
  }

  const handleSubmit = (e: FormSubmit) => {
    e.preventDefault()
    dispatch(login(userLogin))
  }

  return (
    <form onSubmit={handleSubmit} className="form-signin">
      <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
      <div className="form-group mb-3">
        <label htmlFor="account" className="sr-only">Email</label>
        <input type="text" className="form-control" id="account" name="account" value={account} onChange={handleChangeInput} placeholder="Email address" required autoFocus />
      </div>

      <div className="form-group mb-3">
        <label htmlFor="password" className="sr-only">Password</label>
        <input type={typePass ? "text" : "password"} className="form-control" id="password" name="password" value={password} onChange={handleChangeInput} placeholder="Password" required />
        <small onClick={() => setTypePass(!typePass)} className="toggle-password">{typePass ? 'Hide' : 'Show'}</small>
      </div>

      <button type="submit" className="btn btn-lg btn-primary btn-block" disabled={(account && password) ? false : true}>Sign in</button>
    </form>
  )
}

export default LoginPass
